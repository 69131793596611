import { domain, sample, forward, createEvent, PageContext } from "effector-next";
import { message } from "antd";

import { dropSession, requestSession } from "features/viewer";
import { requestFailed, cookiesForRequestSetted, $cookiesForResponse } from "lib/request";

export const appStarted = createEvent<PageContext>();

domain.onCreateStore((store) => {
  store.reset(dropSession);
});

forward({
  from: appStarted,
  to: requestSession,
});

forward({
  from: appStarted.map(({ req }) => req.headers.cookie || ""),
  to: cookiesForRequestSetted,
});

sample({
  source: appStarted,
  clock: $cookiesForResponse,
  fn: ({ res }, cookies) => ({ res, cookies }),
}).watch(({ res, cookies }) => {
  const hSetCookie = res.getHeader("Set-Cookie");
  const setCookie = hSetCookie ? cookies.concat(hSetCookie as string) : cookies;

  res.setHeader("Set-Cookie", setCookie);
});

if (typeof window !== "undefined") {
  requestFailed.watch((error) => {
    if (error.response?.status === 500) {
      const requestUrl = error.config.url;

      message.error(`Request to "${requestUrl}" failed with status code 500`);
    }
  });

  requestFailed.watch((error) => {
    if (error.response?.status === 403) {
      message.error("No access to perform this operation");
    }
  });
  requestFailed.watch((error) => {
    if (error.response?.status === 401) {
      message.error("No access to perform this operation");

      if (location.pathname !== "/" && location.pathname !== "") {
        location.href = "/";
      }
    }
  });
}

// This condition is inlined by the webpack, which allows excluding the logger from the production build.
if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  // import("effector-logger/attach").then(({ attachLogger }) => attachLogger(domain));
}
