import * as React from "react";
import { useStore } from "effector-react";
import { Store } from "effector-next";

import { Rules } from "../../types";
import { userHasPermissions } from "../user-has-permissions";
import { AbacContext, AbacContextValue } from "../abac-context";

export interface AbacProviderProps<User> {
  rules: Rules<User>;
  user: Store<null | User>;
  children?: React.ReactNode;
}

export function AbacProvider<User>({ user, rules, children }: AbacProviderProps<User>) {
  const currentUser = useStore(user);

  const providerValue = React.useCallback<AbacContextValue>(
    (perform, data) => userHasPermissions({ data, rules, perform, user: currentUser }),
    [rules, currentUser],
  );

  return <AbacContext.Provider value={providerValue}>{children}</AbacContext.Provider>;
}
