import * as React from "react";
import { withStart, withHydrate } from "effector-next";
import { ErrorBoundary } from "@sentry/nextjs";
import { compose } from "ramda";
import Error from "next/error";
import Head from "next/head";
import App from "next/app";

import "antd/dist/antd.min.css";

import { appStarted } from "common/init";
import { rules, AbacProvider } from "features/abac";
import { withAuth, $session } from "features/viewer";

const enhance = compose(withStart(appStarted), withHydrate(), withAuth("/"));

class MyApp extends App {
  render() {
    const { pageProps, Component } = this.props;

    // emulate the default behavior of the error component for client errors
    const errorStatusCode = (undefined as unknown) as number;

    return (
      <ErrorBoundary fallback={<Error statusCode={errorStatusCode} />}>
        <AbacProvider rules={rules} user={$session}>
          <React.Fragment>
            <Head>
              <title>Event Schedule</title>
              <link rel="icon" type="image/png" href="/favicon.png" />
              <link rel="shortcut icon" type="image/png" href="/favicon.png" />
              <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1" />
            </Head>
            <Component {...pageProps} />
          </React.Fragment>
        </AbacProvider>
      </ErrorBoundary>
    );
  }
}

export default enhance(MyApp);
