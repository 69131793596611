import { Rule } from "../../types";

/**
 * Checking a rule to see its result
 *
 * @param {Rule<User>} [rule] - Checked rule
 * @param {null | User} [user] - Current user
 * @param {Data} [data] - Data to check for attributes
 */
export function evaluateRule<User, Data>(rule?: Rule<User>, user?: null | User, data?: Data) {
  if (!rule) {
    return false;
  }

  if (typeof rule === "function") {
    return Boolean(rule(user, data));
  }

  return Boolean(rule);
}
