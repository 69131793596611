import { Rules, Action } from "../../types";
import { ensureArray } from "../ensure-array";
import { evaluateRule } from "../evaluate-rule";

export interface UserHasPermissionsParams<User> {
  data?: unknown;
  rules: Rules<User>;
  user?: null | User;
  perform: Action | Action[];
}

/**
 * Checking if a user has access
 *
 * @param {unknown} [data] - Data to check for attributes
 * @param {null | User} [user] - Current user
 * @param {Rules<User>} rules - Rule set
 * @param {Action | Action[]} - perform - Verifiable user actions
 */
export function userHasPermissions<User>({ data, user, rules, perform }: UserHasPermissionsParams<User>) {
  return ensureArray(perform).some((action) => {
    return evaluateRule(rules[action], user, data);
  });
}
